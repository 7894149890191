<template>
  <b-card title="Müşteri Geçmişi">
    <statistic />
    <tab-details />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import Statistic from '@/views/Admin/Customers/View/CustomerHistory/Statistic.vue'
import TabDetails from '@/views/Admin/Customers/View/CustomerHistory/Details.vue'

export default {
  name: 'CustomerHistory',
  components: {
    BCard,
    Statistic,
    TabDetails,
  },
  props: {
    customerId: {
      type: String,
      default: null,
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      if (this.customerId) {
        this.$store.dispatch('customerHistory/getDataList', { id_customers: this.customerId })
      }
    },
  },
}
</script>
