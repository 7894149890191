<template>
  <b-row>
    <b-col>
      <item-statistics
        title="Görüşmeler"
        icon="MessageCircleIcon"
        color="light-success"
        :value="counters.interviews"
      />
    </b-col>
    <b-col>
      <item-statistics
        title="Teklifler"
        icon="FileTextIcon"
        color="light-info"
        :value="counters.offers"
      />
    </b-col>
    <b-col>
      <item-statistics
        title="Siparişler"
        icon="BookIcon"
        color="light-danger"
        :value="counters.orders"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ItemStatistics from '@/views/Admin/Customers/View/CustomerHistory/ItemStatistics.vue'

export default {
  name: 'Statistic',
  components: {
    BRow,
    BCol,
    ItemStatistics,
  },
  computed: {
    counters() {
      return this.$store.getters['customerHistory/counters']
    },
  },
}
</script>
