<template>
  <div>
    <b-media no-body>
      <b-media-aside
        class="mr-2"
      >
        <b-avatar
          size="48"
          :variant="color"
        >
          <feather-icon
            size="24"
            :icon="icon"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body class="my-auto">
        <h4 class="font-weight-bolder mb-0">
          {{ value }}
        </h4>
        <b-card-text class="font-small-3 mb-0">
          {{ title }}
        </b-card-text>
      </b-media-body>
    </b-media>
  </div>
</template>

<script>
import {
  BMedia, BMediaAside, BAvatar, BMediaBody, BCardText,
} from 'bootstrap-vue'

export default {
  name: 'ItemStatistics',
  components: {
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>
