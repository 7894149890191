<template>
  <div>
    <b-card no-body>
      <offer-summary />
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Ürünler</b-card-title>
      </b-card-header>
      <products />
    </b-card>
    <b-card title="Özel Şartlar">
      <terms />
      <div class="mt-1 text-center">
        <div class="d-inline">
          <b-button
            :disabled="dataItem.id_offer_statuses === '4'"
            variant="warning"
            class="mr-2"
            :to="'/app/offers/edit/' + dataItem.id"
          >
            <FeatherIcon icon="EditIcon" />
            Güncelle
          </b-button>
        </div>
        <div class="d-inline">
          <b-button
            variant="info"
            class="mr-2"
            :to="'/app/offers/revised/' + dataItem.id"
          >
            <FeatherIcon icon="EditIcon" />
            Revize Oluştur
          </b-button>
        </div>
        <div class="d-inline">
          <b-button
            :disabled="dataItem.id_offer_statuses !== '1'"
            variant="success"
            @click="submitCustomer"
          >
            <FeatherIcon icon="SendIcon" />
            Müşteriye Gönder
          </b-button>
        </div>
      </div>
    </b-card>
    <customer-send-modal
      :get-data="getData"
    />
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BButton,
} from 'bootstrap-vue'
import OfferSummary from '@/views/Admin/Offers/View/OfferSummary.vue'
import Products from '@/views/Admin/Offers/View/Products.vue'
import Terms from '@/views/Admin/Offers/View/Terms.vue'
import CustomerSendModal from '@/views/Admin/Offers/View/CustomerSendModal.vue'

export default {
  name: 'OffersView',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    OfferSummary,
    Products,
    Terms,
    CustomerSendModal,
  },
  props: {
    getData: {
      type: Function,
      required: false,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    customerSend() {
      return this.$store.getters['offers/customerSend']
    },
  },
  methods: {
    submitCustomer() {
      this.customerSend.modalStatus = true
      this.getCustomerAccounts()
    },
    getCustomerAccounts() {
      this.$store.dispatch('customerAccounts/getDataList', {
        select: [
          'customer_accounts.id AS id',
          'customer_accounts.fullname AS title',
          'customer_accounts.email AS email',
        ],
        where: {
          'customer_accounts.id_customers': this.dataItem.id_customers,
          'customer_accounts.status': 1,
        },
      })
    },
  },
}
</script>
