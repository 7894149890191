<template>
  <div v-html="dataItem.terms" />
</template>

<script>

export default {
  name: 'Terms',
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
}
</script>
