<template>
  <b-form-group
    label="Saat"
    label-for="push_time"
  >
    <v-select
      id="push_time"
      v-model="dataItem.push_time"
      :options="cronJobTimes"
      label="time"
      :reduce="item => item.time"
      placeholder="Saat"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'PushDate',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    dataItem() {
      return this.$store.getters['pushNotifications/dataItem']
    },
    cronJobTimes() {
      return this.$store.getters['pushNotifications/cronjobTimes']
    },
  },
}
</script>
