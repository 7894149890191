<template>
  <b-tabs
    fill
    class="mt-2"
  >
    <b-tab>
      <template
        #title
        active
      >
        <feather-icon icon="MessageCircleIcon" />
        <span>Görüşmeler</span>
      </template>
      <interviews
        v-if="counters.interviews > 0"
        :data-list="dataList.interviews"
      />
      <b-alert
        v-else
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" /> Görüşme geçmişi bulunmamaktadır.
        </div>
      </b-alert>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="FileTextIcon" />
        <span>Teklifler</span>
      </template>
      <offers
        v-if="counters.offers > 0"
        :data-list="dataList.offers"
      />
      <b-alert
        v-else
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" /> Teklif geçmişi bulunmamaktadır.
        </div>
      </b-alert>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="BookIcon" />
        <span>Siparişler</span>
      </template>
      <orders
        v-if="counters.orders > 0"
        :data-list="dataList.orders"
      />
      <b-alert
        v-else
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" /> Sipariş geçmişi bulunmamaktadır.
        </div>
      </b-alert>
    </b-tab>
  </b-tabs>
</template>
<script>
import { BTabs, BTab, BAlert } from 'bootstrap-vue'
import Interviews from '@/views/Admin/Customers/View/CustomerHistory/Interviews.vue'
import Offers from '@/views/Admin/Customers/View/CustomerHistory/Offers.vue'
import Orders from '@/views/Admin/Customers/View/CustomerHistory/Orders.vue'

export default {
  name: 'Details',
  components: {
    BTabs,
    BTab,
    BAlert,
    Interviews,
    Offers,
    Orders,
  },
  computed: {
    counters() {
      return this.$store.getters['customerHistory/counters']
    },
    dataList() {
      return this.$store.getters['customerHistory/dataList']
    },
  },
}
</script>
