<template>
  <b-form-group
    label="Tarih"
    label-for="push_date"
  >
    <b-form-datepicker
      id="push_date"
      v-model="dataItem.push_date"
      v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
      locale="tr"
      start-weekday="1"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'

export default {
  name: 'PushDate',
  components: {
    BFormGroup,
    BFormDatepicker,
  },
  computed: {
    dataItem() {
      return this.$store.getters['pushNotifications/dataItem']
    },
  },
}
</script>
