<template>
  <b-row class="mb-2">
    <b-col cols="12">
      <title-text />
    </b-col>
    <b-col cols="12">
      <content-text />
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <push-date />
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <push-time />
    </b-col>
    <b-col
      cols="12"
    >
      <div class="d-flex justify-content-between">
        <b-button
          variant="primary"
          @click="submitForm(false)"
        >
          <FeatherIcon icon="CalendarIcon" />
          Bildirimi Planla
        </b-button>
        <b-button
          variant="success"
          @click="submitForm(true)"
        >
          <FeatherIcon icon="SendIcon" />
          Bildirimi Şimdi Gönder
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import TitleText from '@/views/Admin/Catalogs/elements/PushNotificationForm/TitleText.vue'
import ContentText from '@/views/Admin/Catalogs/elements/PushNotificationForm/ContentText.vue'
import PushDate from '@/views/Admin/Catalogs/elements/PushNotificationForm/PushDate.vue'
import PushTime from '@/views/Admin/Catalogs/elements/PushNotificationForm/PushTime.vue'

export default {
  name: 'PushNotificationForm',
  components: {
    BRow,
    BCol,
    BButton,
    TitleText,
    ContentText,
    PushDate,
    PushTime,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  created() {
    this.getCronJobTimes()
  },
  methods: {
    getCronJobTimes() {
      this.$store.dispatch('pushNotifications/getCronJobTimes')
    },
  },
}
</script>
