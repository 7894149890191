<template>
  <div>
    <b-form-checkbox
      v-model="dataItem.autoPush"
      switch
      value="1"
    >
      Uygulama Bildirimi Gönder
    </b-form-checkbox>
    <b-alert
      v-if="dataItem.autoPush"
      show
      variant="info"
    >
      <div class="alert-body">
        <div class="d-flex align-items-center">
          <div class="mr-1">
            <FeatherIcon
              icon="InfoIcon"
              size="18"
            />
          </div>
          <div>
            <div>Teklif durumu için otomatik uygulama bildirimi gönderilecektir.</div>
            <div class="font-small-2 text-danger font-italic">
              Uygulama bildirimi aktif tüm hesaplara gönderilir.
            </div>
          </div>
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BFormCheckbox, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'OfferAutoPush',
  components: {
    BFormCheckbox,
    BAlert,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
}
</script>
